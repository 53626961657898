<template>
  <v-card flat>
    <v-card-text>
      <apex-chart
        v-if="dataIsLoaded"
        :key="chartKey"
        height="400"
        :options="ColumnChartOptions"
        :series="seriesData"
        type="line"
      ></apex-chart>
    </v-card-text>
  </v-card>
</template>

<script>
import { updateSeriesWithColors, currencyConvert } from '@/util/functions'
import { ref, watchEffect } from 'vue'
import { useWindowSize } from '@vueuse/core'
import { useDataStore } from '@/stores/Data'
import { ColumnChartOptions as initialOptions } from '@/assets/chartOptions/market'
import { getInstance } from '@/auth/authWrapper'
import regression from 'regression'

export default {
    name: 'StackedTimeSeriesMonthlyMfr',
    props: {
        series: {
            type: String,
            default: 'monthlyStackedSalesByMfrTar',
        },
    },
    setup(props) {
        const chartKey = ref(0)
        const seriesData = ref([])
        const { height } = useWindowSize()
        const dataStore = useDataStore()
        const ColumnChartOptions = ref({ ...initialOptions })
        const dataIsLoaded = ref(false)

        watchEffect(() => {
            seriesData.value = []
            const response = dataStore.chartData[props.series]

            if (response?.value?.data) {
                const { data } = response.value
                seriesData.value = Array.isArray(data) ? updateSeriesWithColors(data) : []

                const mfrArray = getInstance().user['https://api.pharmaguide.ca/claims/app_metadata'].mfr
                const sampleSeries = seriesData.value[0]?.data || []
                const COMData = Array(sampleSeries.length).fill(0)

                mfrArray.forEach(mfr => {
                    const mfrData = seriesData.value.find(series => series.name === mfr)?.data || []
                    COMData.forEach((comData, i) => {
                        COMData[i] += mfrData[i] || 0
                    })
                })

                let trendLineData = sampleSeries

                // Remove existing TOTAL and Trend Line
                seriesData.value = seriesData.value.filter(series => series.name !== 'TOTAL' && series.name !== 'Trend Line')

                // Add TOTAL and Trend Line only if there's more than one manufacturer
                if (mfrArray.length > 1) {
                    seriesData.value.push({
                        name: 'TOTAL',
                        data: COMData,
                        fillColor: '#2084f7',
                        strokeColor: '#2084f7',
                        color: '#2084f7',
                        type: 'line'
                    })

                    trendLineData = COMData
                }

                const commonColumnChartOptions = {
                    xaxis: {
                        categories: [...new Set(response.value.xValues)],
                    },
                    tooltip: {
                        y: {
                            formatter(value) {
                                return currencyConvert(value)
                            },
                        },
                    },

                    dataLabels: {
                        enabled: false,
                        textAnchor: 'middle',
                        offsetY: 10,
                        formatter(val) {
                            return currencyConvert(val)
                        },
                    },
                    legend: {
                        show: true,
                        position: 'bottom',
                    },
                    markers: {
                        size: 5,

                        showNullDataPoints: true,
                        hover: {
                            size: undefined,
                            sizeOffset: 3
                        }
                    }

                }

                ColumnChartOptions.value = {
                    ...ColumnChartOptions.value,
                    ...commonColumnChartOptions,
                }

                // prepare data for regression
                const regressionData = trendLineData.map((val, i) => [i, val])

                // perform linear regression
                const result = regression.linear(regressionData)

                // generate trend line data
                const trendLineDataWithLabels = result.points.map(point => point[1])

                seriesData.value.push({
                    name: 'Trend Line',
                    data: trendLineDataWithLabels,
                    type: 'line',
                    color: '#00e396' // specify the color of the trend line
                })

                // update the enabledOnSeries array to exclude the trend line series
                commonColumnChartOptions.dataLabels.enabledOnSeries = seriesData.value
                    .filter((series, index) => series.name !== 'Trend Line')
                    .map((_, index) => index)

                chartKey.value += 1
                dataIsLoaded.value = true
            }
        })

        return {
            seriesData,
            chartKey,
            height,
            ColumnChartOptions,
            dataIsLoaded
        }
    }
}
</script>
